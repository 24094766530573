<template>
  <el-container>
    <!-- 搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="所属机构:">
          <el-select v-model="formInline.orgId" placeholder="选择所属机构">
            <!--              <el-option-->
            <!--                  v-for="item in typeList"-->
            <!--                  :key="item.queTypeId"-->
            <!--                  :label="item.typeName"-->
            <!--                  :value="item.queTypeId">-->
            <!--              </el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item label="题库分类:" style="padding-left: 8px">
          <el-select v-model="formInline.queTypeId" placeholder="选择题目库分类">
            <el-option
                v-for="item in typeList"
                :key="item.queTypeId"
                :label="item.typeName"
                :value="item.queTypeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="题目:" style="padding-left: 8px">
          <el-input v-model="formInline.queName" placeholder="请输入题目"></el-input>
        </el-form-item>
        <el-form-item label="题目分类:" style="padding-left: 8px">
          <el-select v-model="formInline.type" placeholder="默认分类">
            <el-option label="单选" value="1"></el-option>
            <el-option label="多选" value="2"></el-option>
            <el-option label="其他" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="saveDrawer=true" style="width: 100px;">添加题目</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="innerDrawer = true" type="danger">批量导入</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加题目信息 -->
    <el-drawer
        title="添加题目信息"
        :visible.sync="saveDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="题目名称:">
              <el-input type="textarea" v-model="form.queName"></el-input>
            </el-form-item>
            <el-form-item label="题目分类:">
              <el-select v-model="form.queTypeId" placeholder="请选择活动区域" style="width: 320px">
                <el-option
                    v-for="item in typeList"
                    :key="item.queTypeId"
                    :label="item.typeName"
                    :value="item.queTypeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题目选项:">
              <el-input type="textarea" v-model="form.options"></el-input>
            </el-form-item>
            <el-form-item label="正确答案:">
              <el-input v-model="form.answer"></el-input>
            </el-form-item>
            <el-form-item label="默认分类:">
              <el-select v-model="form.type" placeholder="默认分类" style="width: 320px">
                <el-option label="单选" value="1"></el-option>
                <el-option label="多选" value="2"></el-option>
                <el-option label="其他" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题目分数:">
              <el-input v-model="form.scores"></el-input>
            </el-form-item>
            <el-form-item label="题目详解:">
              <el-input type="textarea" v-model="form.detailed"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="addSubmit" style="width: 300px">添加题目</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 修改题目信息 -->
    <el-drawer
        title="修改题目信息"
        :visible.sync="updateDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="updateForm" label-width="80px">
            <el-form-item label="题目名称:">
              <el-input type="textarea" v-model="updateForm.queName"></el-input>
            </el-form-item>
            <el-form-item label="题目分类:">
              <el-select v-model="updateForm.queTypeId" placeholder="请选择活动区域" style="width: 320px">
                <el-option
                    v-for="item in typeList"
                    :key="item.queTypeId"
                    :label="item.typeName"
                    :value="item.queTypeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题目选项:">
              <el-input type="textarea" v-model="updateForm.options"></el-input>
            </el-form-item>
            <el-form-item label="正确答案:">
              <el-input v-model="updateForm.answer"></el-input>
            </el-form-item>
            <el-form-item label="默认分类:">
              <el-select v-model="updateForm.type" placeholder="默认分类" style="width: 320px">
                <el-option
                    v-for="item in options"
                    :key="item.queTypeId"
                    :label="item.typeName"
                    :value="item.queTypeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题目分数:">
              <el-input v-model="updateForm.scores"></el-input>
            </el-form-item>
            <el-form-item label="题目详解:">
              <el-input type="textarea" v-model="updateForm.detailed"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="modifyQue" style="width: 300px">修改保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 批量导入题目 -->
    <el-drawer
        title="批量导入题目"
        :visible.sync="innerDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
          <div class="update-box">
            <el-upload
                class="upload-demo"
                :action="UploadUrl()"
                drag
                accept=".csv"
                :on-preview="handlePreview"
                :on-change="fileChange"
                :before-remove="beforeRemove"
                :limit="1"
                :http-request="uploadFile"
                :auto-upload="true"
                :on-exceed="handleExceed"
                :file-list="fileList">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div slot="tip" class="el-upload__tip">只能上传CSV的文件</div>
            </el-upload>
          </div>
      </div>
    </el-drawer>
    <!-- 记录展示 -->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="queList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="orgName"
            label="所属机构"
            width="100">
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="题库"
            width="150">
        </el-table-column>
        <el-table-column
            prop="queTypeName"
            label="分类"
            width="80">
        </el-table-column>
        <el-table-column
            prop="queName"
            label="题目"
            width="500">
        </el-table-column>
        <el-table-column
            prop="scores"
            label="分数"
            width="50">
        </el-table-column>
        <el-table-column
            prop="answer"
            label="答案"
            width="120">
        </el-table-column>
        <el-table-column
            prop="detailed"
            label="详解"
            width="300">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="deleteQuestionById(scope.$index, queList)"
                type="text"
                size="small">
              删除
            </el-button>
            <el-button
                @click.native.prevent="modifyQuestion(scope.$index, queList)"
                @click="updateDrawer=true"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
// 导入查询的方法
import {
  queryQueTypeList,
  queryPageQueList,
  deletedQuestion,
  uploadData,
  saveQuestion, queryQuestion, modifyQuestion
} from "../../../module/reqapi/routepath";

export default {
  name: "QuestionBank",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      innerDrawer: false,
      queList: [],
      fileList: [],
      typeList: [],
      option: '',
      updateForm: {
        queId: '',
        queName: '',
        queTypeId: '',
        options: JSON.stringify({}),
        answer: '',
        type: '',
        scores: '',
        detailed: ''
      },
      form: {
        queName: '',
        queTypeId: '',
        options: JSON.stringify({}),
        answer: '',
        type: '',
        scores: '',
        detailed: ''
      },
      formInline: {
        orgId: '',
        queTypeId: '',
        queName: '',
        type: ''
      },
      options: [
        {
          queTypeId: 1,
          typeName: '单选'
        },
        {
          queTypeId: 2,
          typeName: '多选'
        },
        {
          queTypeId: 3,
          typeName: '其他'
        }
      ],
      saveDrawer: false,
      updateDrawer: false
    }
  },
  mounted() {
    this.getQuestionList();
    this.getTypeList();
  },
  methods: {
    // 获取题库分类信息列表
    getTypeList() {
      queryQueTypeList({}).then((req) => {
        if (req.code == 200) {
          this.typeList = req.data;
        }
      })
    },
    // 获取题库的列表
    getQuestionList() {
      queryPageQueList({
        queTypeId: this.formInline.queTypeId,
        type: this.formInline.type,
        queName: this.formInline.queName,
        pageSize: this.pageSize,
        pageNo: this.pageNo
      }).then((req) => {
        this.queList = req.data.list;
        this.count = req.data.count;
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.getQuestionList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.getQuestionList()
    },
    // 根据条件查询题目列表
    onSubmit() {
      this.getQuestionList()
    },
    // 添加题目
    addSubmit() {
      this.form.options = JSON.parse(this.form.options)
      saveQuestion({
        queTypeId: this.form.queTypeId,
        queName: this.form.queName,
        options: this.form.options,
        answer: this.form.answer,
        type: this.form.type,
        scores: this.form.scores,
        detailed: this.form.detailed
      }).then((req) => {
        if (req.code == 200) {
          this.$message.success("保存成功");
          this.saveDrawer = false;
          this.getQuestionList();
          this.form.options = JSON.stringify(this.form.options)
        }
      })
    },
    // 删除题目
    deleteQuestionById(index, rows) {
      deletedQuestion({
        id: rows[index].queId,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getQuestionList()
        }
      })
    },
    // 修改和查询题目
    modifyQuestion(index, rows) {
      queryQuestion({
        id: rows[index].queId
      }).then((res) => {
        if (res.code == 200) {
          this.updateForm = res.data
        }
      })
    },
    // 文件批量上传
    UploadUrl() {
      return '';
    },
    fileChange(file) {
      this.fileList.push(file.raw);
      console.log(this.fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    // 文件限制钩子函数
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件,请刷新页面后重试。`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件上传的开始
    uploadFile(val) {
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        const form = new FormData();
        // file和flag分别为后端要求上传的参数名，类似于post、get等请求中的参数
        form.append('file', val.file);
        form.append('flag', true);
        this.fileList = [];
        uploadData(form).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.data);
            this.innerDrawer = false;
          }
        })
      }
    },
    // 修改题目的信息
    modifyQue() {
      let allList = this.updateForm
      this.updateForm.options = JSON.parse(this.updateForm.options)
      modifyQuestion(allList).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
        }
        this.updateDrawer = false;
        this.getQuestionList();
      })
    },
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

.drawer-style {
  height: 75%;
  margin-top: 160px;
  margin-right: 400px;
  margin-left: 400px;
  border-radius: 10px;
  text-align: center;
}

.drawer-box {
  width: 100%;
  height: 100%;
  background: #F4F4F4;
}

/deep/ .el-drawer__header {
  background: #F4F4F4;
  font-size: 16px;
  margin-bottom: 0.5px;
  padding: 10px 10px;
}

.addTaskType {
  margin-left: 200px;
  padding-top: 80px;
}

.update-box{
  padding-top: 200px;
}

</style>