<template>
  <el-container>
    <!--搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="用户名:" style="padding-left: 4px">
          <el-input v-model="formInline.roleName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="所属机构:" style="padding-left: 10px">
          <el-select v-model="formInline.orgId" placeholder="请选择所属机构">
            <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属角色:" style="padding-left: 4px">
          <el-select v-model="formInline.roleId" placeholder="请选择所属角色">
            <el-option
                v-for="item in roleList"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="saveDrawer = true" style="width: 100px;">添加用户</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--添加用户信息-->
    <el-drawer
        title="添加用户信息"
        :visible.sync="saveDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="用户名:">
              <el-input v-model="form.userName"></el-input>
            </el-form-item>
            <el-form-item label="昵称:">
              <el-input v-model="form.nickName"></el-input>
            </el-form-item>
            <el-form-item label="密码:">
              <el-input v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="邮箱:">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="手机:">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="角色:">
              <el-select v-model="form.roleId" placeholder="默认分类" style="width: 320px">
                <el-option
                    v-for="item in roleList"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="onSubmit" style="width: 300px">添加用户</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!--查看用户信息-->
    <el-drawer
        title="修改菜单信息"
        :visible.sync="updateDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="updateForm" label-width="80px">
            <el-form-item label="用户名:">
              <el-input v-model="updateForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="昵称:">
              <el-input v-model="updateForm.nickName"></el-input>
            </el-form-item>
            <el-form-item label="密码:">
              <el-input v-model="updateForm.password"></el-input>
            </el-form-item>
            <el-form-item label="邮箱:">
              <el-input v-model="updateForm.email"></el-input>
            </el-form-item>
            <el-form-item label="手机:">
              <el-input v-model="updateForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="角色:">
              <el-select v-model="updateForm.roleId" placeholder="默认分类" style="width: 320px">
                <el-option
                    v-for="item in roleList"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="updateForm.remark"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="modifyUserInfo" style="width: 300px">修改用户</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!--记录展示-->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="sysUserList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="orgName"
            label="所属机构"
            width="120">
        </el-table-column>
        <el-table-column
            prop="roleName"
            label="角色名称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="用户名"
            width="120">
        </el-table-column>
        <el-table-column
            prop="nickName"
            label="昵称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="email"
            label="邮箱"
            width="170">
        </el-table-column>
        <el-table-column
            prop="phone"
            label="手机号"
            width="170">
        </el-table-column>
        <el-table-column
            prop="status"
            label="用户状态"
            width="100">
        </el-table-column>
        <el-table-column
            prop="version"
            label="版本"
            width="100">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="角色描述"
            width="190">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="goSysUserInfo(scope.$index, sysUserList)"
                @click="updateDrawer=true"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!--分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
// 引入获取信息的接口
import {
  getOrgListByOrgId,
  getRoleListByOrgId,
  getSysUserInfo,
  getSysUserList,
  saveSysUserInfo, updateSysUserInfo
} from "../../../module/reqapi/routepath";

// 关于JS相关的操作
export default {
  name: "userManager",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        roleName: '',
        orgId: '',
        roleId: '',
      },
      form: {
        userName: '',
        nickName: '',
        password: '',
        email: '',
        phone: '',
        remark: '',
        roleId: ''
      },
      updateForm: {
        id: '',
        userName: '',
        nickName: '',
        password: '',
        email: '',
        phone: '',
        remark: '',
        roleId: ''
      },
      saveDrawer: false,
      updateDrawer: false,
      sysUserList: [],
      orgList: [],
      roleList: [],
    }
  },
  created() {
    this.getSysUserList()
    this.getRoleListByOrgId()
    this.getOrgListByOrgId()
  },
  methods: {
    // 获取管理员的信息
    getSysUserList() {
      getSysUserList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.sysUserList = res.data.list
          this.count = res.data.count
        }
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.getTypeList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.getTypeList()
    },
    // 条件查询角色列表
    onSubmit() {
      this.getOrgRoleList()
    },
    // 添加管理员信息
    addOrgUserInfo() {
      saveSysUserInfo({
        userName: this.form.userName,
        nickName: this.form.nickName,
        password: this.$getRsaCode(this.form.password),
        email: this.form.email,
        phone: this.form.phone,
        remark: this.form.remark,
        roleId: this.form.roleId
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.getSysUserList();
          this.saveDrawer = false;
        }
      })
    },
    // 跳去用户信息页面
    goSysUserInfo(index, rows) {
      getSysUserInfo({
        id: rows[index].id
      }).then((res) => {
        if (res.code == 200) {
          this.updateForm = res.data;
          this.updateForm.id = rows[index].id;
        }
      })
    },
    modifyUserInfo() {
      updateSysUserInfo({
        id: this.updateForm.id,
        userName: this.updateForm.userName,
        nickName: this.updateForm.nickName,
        password: this.$getRsaCode(this.updateForm.password),
        email: this.updateForm.email,
        phone: this.updateForm.phone,
        remark: this.updateForm.remark,
        roleId: this.updateForm.roleId
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.getSysUserList()
          this.updateDrawer = false
        }
      })
    },
    // 获取角色列表
    getRoleListByOrgId() {
      getRoleListByOrgId().then((res) => {
        this.roleList = res.data
      })
    },
    // 获取机构列表信息
    getOrgListByOrgId() {
      getOrgListByOrgId().then((res) => {
        this.orgList = res.data
      })
    }
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}


.drawer-style {
  height: 75%;
  margin-top: 160px;
  margin-right: 400px;
  margin-left: 400px;
  border-radius: 10px;
  text-align: center;
}

.drawer-box {
  width: 100%;
  height: 100%;
  background: #F4F4F4;
}

/deep/ .el-drawer__header {
  background: #F4F4F4;
  font-size: 16px;
  margin-bottom: 0.5px;
  padding: 10px 10px;
}

.addTaskType {
  margin-left: 200px;
  padding-top: 80px;
}

</style>