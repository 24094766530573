<template>
  <el-container>
    <!-- 搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="用户名:" style="padding-left: 4px">
          <el-input v-model="formInline.nickName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" style="padding-left: 4px">
          <el-input v-model="formInline.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="付费状态:" style="padding-left: 4px">
          <el-select v-model="formInline.isVip" placeholder="付费状态">
            <el-option label="普通" value="0"></el-option>
            <el-option label="VIP" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户状态:" style="padding-left: 4px">
          <el-select v-model="formInline.status" placeholder="用户状态">
            <el-option label="正常" value="0"></el-option>
            <el-option label="冻结" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 记录展示 -->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="memberList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            label="头像"
            width="80">
          <template slot-scope="scope">
            <el-avatar shape="square" :size="30" :src="scope.row.userImg"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
            prop="orgName"
            label="所属机构"
            width="120">
        </el-table-column>
        <el-table-column
            prop="nickName"
            label="昵称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="phone"
            label="手机号"
            width="120">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="用户名"
            width="150">
        </el-table-column>
        <el-table-column
            prop="email"
            label="邮箱"
            width="160">
        </el-table-column>
        <el-table-column
            prop="openId"
            label="微信唯一标识"
            width="300">
        </el-table-column>
        <el-table-column
            align="center"
            label="付费标识(VIP)"
            width="110">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.isVip == 0? '未开通':'开通'" palacement="top">
              <el-switch
                  v-model="scope.row.isVip"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="modifyQuestion(scope.$index, queList)"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 分页展示-->
    <el-footer  style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
// 引入相关的接口
import {queryPageCusOrgCus} from "../../../module/reqapi/routepath";
// 关于数据和方法
export default {
  name: "memberList",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        nickName: '',
        phone: '',
        isVip: '',
        status: ''
      },
      memberList: [],
    }
  },
  created() {
    this.getMemberInfoList()
  },
  methods: {
    // 获取会员的信息
    getMemberInfoList() {
      queryPageCusOrgCus({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        nickName: this.formInline.nickName,
        status: this.formInline.status,
        phone: this.formInline.phone,
        isVip: this.formInline.isVip
      }).then((res) => {
        if (res.code == 200) {
          this.memberList = res.data.list
          this.count = res.data.count
        }
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.getMemberInfoList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.getMemberInfoList()
    },
    // 提交查询条件
    onSubmit() {
      this.getMemberInfoList()
    }
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

</style>