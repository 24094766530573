<template>
  <el-container>
    <!-- 搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="分类名:" style="padding-left: 4px">
          <el-input v-model="formInline.typeName" placeholder="请输入任务分类"></el-input>
        </el-form-item>
        <el-form-item label="描述:" style="padding-left: 4px">
          <el-input v-model="formInline.typeDes" placeholder="请输入分类描述"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="saveDrawer=true" style="width: 100px">添加分类</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加定时任务分类的弹窗 -->
    <el-drawer
        title="添加定时任务分类"
        :visible.sync="saveDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="任务分类:">
              <el-input v-model="form.typeName"></el-input>
            </el-form-item>
            <el-form-item label="分类描述:">
              <el-input type="textarea" v-model="form.typeDes"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="saveTaskTypeInfo" style="width: 300px">提交保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 修改定时任务分类的弹窗 -->
    <el-drawer
        title="修改定时任务分类"
        :visible.sync="updateDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="updateForm" label-width="80px">
            <el-form-item label="任务分类:">
              <el-input v-model="updateForm.typeName"></el-input>
            </el-form-item>
            <el-form-item label="分类描述:">
              <el-input type="textarea" v-model="updateForm.typeDes"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="modifyTaskTypeInfo" style="width: 300px">修改保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 记录展示 -->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="taskTypeList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="typeId"
            label="分类ID"
            width="200">
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="分类名"
            width="100">
        </el-table-column>
        <el-table-column
            prop="typeDes"
            label="分类描述"
            width="900">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="updateTaskTypeInfo(scope.$index, taskTypeList)"
                @click="updateDrawer=true"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
import {queryTaskTypeList, saveTaskTypeInfo, updateTaskTypeInfo} from "../../../module/reqapi/routepath";

export default {
  name: "taskTypeManager",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        typeName: '',
        typeDes: ''
      },
      form: {
        typeName: '',
        typeDes: ''
      },
      updateForm: {
        typeId: '',
        typeName: '',
        typeDes: ''
      },
      saveDrawer: false,
      updateDrawer: false,
      taskTypeList: [],
    }
  },
  created() {
    this.queryTaskTypeList()
  },
  methods: {
    // 修改定时任务分类信息
    updateTaskTypeInfo(index, rows) {
      this.updateForm = rows[index];
    },
    modifyTaskTypeInfo() {
      updateTaskTypeInfo({
        typeId: this.updateForm.typeId,
        typeName: this.updateForm.typeName,
        typeDes: this.updateForm.typeDes
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.queryTaskTypeList();
          this.updateDrawer = false;
        }
      })
    },
    // 添加定时任务分类信息
    saveTaskTypeInfo() {
      saveTaskTypeInfo({
        typeName: this.form.typeName,
        typeDes: this.form.typeDes
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.queryTaskTypeList();
          this.saveDrawer = false;
        }
      })
    },
    // 获取定时任务的分类信息
    queryTaskTypeList() {
      queryTaskTypeList({
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        typeName: this.formInline.typeName,
        typeDes: this.formInline.typeDes
      }).then((res) => {
        this.taskTypeList = res.data.list;
        this.count = res.data.count;
      })

    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.queryTaskTypeList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.queryTaskTypeList()
    },
    // 条件查询
    onSubmit() {
      this.queryTaskTypeList()
    }
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

.drawer-style {
  height: 75%;
  margin-top: 160px;
  margin-right: 400px;
  margin-left: 400px;
  border-radius: 10px;
  text-align: center;
}

.drawer-box {
  width: 100%;
  height: 100%;
  background: #F4F4F4;
}

/deep/ .el-drawer__header {
  background: #F4F4F4;
  font-size: 16px;
  margin-bottom: 0.5px;
  padding: 10px 10px;
}

.addTaskType {
  margin-left: 200px;
  padding-top: 200px;
}

</style>