<template>
  <el-container class="container">
    <!--官网导航栏开始-->
    <el-header style="height: 69px; width: 100%">
      <div class="com-logo"></div>
      <div class="header-content">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item>
            <img style="max-width: 136px;max-height: 36px "
                 src="https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/favicon.png"/>
          </el-menu-item>
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">客户案列</el-menu-item>
          <el-menu-item index="3">新闻资讯</el-menu-item>
          <el-menu-item index="4">关于思网</el-menu-item>
          <el-menu-item index="5">账号申请</el-menu-item>
          <el-submenu index="6">
            <template slot="title">登录入口</template>
            <el-menu-item index="6-1" style="width: 150px;text-align: center">企管后台</el-menu-item>
            <el-menu-item index="6-2" style="width: 150px;text-align: center">服务后台</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-header>
    <!--官网导航栏结束-->
    <!--官网中间内容开始-->
    <el-main>
      <router-view></router-view>
    </el-main>
    <!--官网中间内容结束-->
    <el-footer class="footer">
      <!--      <el-text class="words">Copyright 2021 szsinet.com All Rights Reserved 深圳市思网科技有限公司 版权所有 粤ICP备2021083141号</el-text>-->
      <el-text class="words">
        Copyright 2021
        <a href="https://szsinet.com">szsinet.com</a> All Rights Reserved 深圳市思网科技有限公司版权所有
        <a href="https://beian.miit.gov.cn/">粤ICP备2021083141号</a>
      </el-text>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "comIndex",
  data() {
    return {
      activeIndex: '1',
    }
  },
  created() {
    this.goComShowPage()
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      switch (key) {
        case '1':
          this.$router.push({
            path: "/comShow"
          })
          break;
        case '2':
          this.$router.push({
            path: "/customerCase"
          })
          break;
        case '3':
          this.$router.push({
            path: "/newsShow"
          })
          break;
        case '4':
          this.$router.push({
            path: "/companyInfo"
          })
          break;
        case '5':
          this.$router.push({
            path: "/Register"
          })
          break;
        case '6-1':
          this.$router.push({
            path: "/Login"
          })
          break;
      }
    },
    goComShowPage() {
      this.$router.push({
        path: "/comShow"
      })
    }
  }
}
</script>

<style scoped>
.el-header {
  position: fixed;
  z-index: 100;
  text-align: center;
  line-height: 68px;
  margin-top: -8px;
}

.com-logo {
  margin-left: 18%;
  padding: 8px 25px 0 0;
  float: left;
}

.com-logo img {
  margin-bottom: 0;
  margin-top: -2px;
  border: none;
  vertical-align: middle;
  width: auto;
}

.footer {
  margin-top: 150px;
}

.words {
  font-size: 12px;
  font-weight: bold;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.el-footer {
  background-color: #F4F4F4;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  padding-top: 70px;
  background-color: #FFFFFF;
  color: #333;
  text-align: center;
}

.el-menu-item {
  padding: 0 40px;
}

.bg-purple {
  background: #B3C0D1;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-select {
  display: inline-block;
  position: relative;
}

</style>