<template>

</template>


<script>

export default {
  name: "articleType"
}
</script>

<style scoped>

</style>