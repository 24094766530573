import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './module/plugins/element.js';
import router from 'vue-router';
import JSEncrypt from "jsencrypt";
import Routes from "./router/index";

Vue.use(ElementUI);

Vue.config.productionTip = true;
const myRouter = new router({
    routes: Routes,
    mode: "history"
});

Vue.prototype.$getRsaCode = (str) => {
    const PUBLIC_KEY =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCSRKjDHCDNCivULCyFwibaPZyfKCQM6fcQ8KdpqVRQjzBMSZQ8EQrmSbpgcef4l38wlRRWU5UJRSgG39lJu3Tyo+0+Tu0UGoaj7suoujGIC8Db2jFXPTqrNO19qSc302v+h3f1JjU7BStKXjHU2bLdSY/qMhM2nCxmlHHoygUa+QIDAQAB";
    let encryptStr = new JSEncrypt();
    encryptStr.setPublicKey(PUBLIC_KEY);
    let data = encryptStr.encrypt(str.toString());
    return data;
};

// 富文本编辑器工具
import useMiniMCE from './module/template/MiniMCE'

useMiniMCE()

new Vue({
    render: h => h(App),
    router: myRouter
}).$mount('#app');


