import request from "../../module/reqapi/request";

// 后台登陆信息
export function getLogin(data) {
    return request({
        url: "/sys/open/login",
        method: "post",
        data,
    });
}

// 机构申请账号接口
export function orgRegister(data) {
    return request({
        url: "/sys/open/register",
        method: "post",
        data,
    });
}

// 获取后台管理员的信息
export function getUserInfo() {
    return request({
        url: "/sys/user/user/query",
        method: "get",
    });
}

// 获取题库的类型列表
export function getQuestionTypeList(data) {
    return request({
        url: "/que/type/query",
        method: "post",
        data,
    });
}

// 获取后台管理员的信息
export function queryQueTypeList(data) {
    return request({
        url: "/que/list/type/query",
        method: "post",
        data,
    });
}

// 保存题库分类信息
export function saveQueType(data) {
    return request({
        url: "/que/type/save",
        method: "post",
        data,
    })
}

// 查询行业分类列表
export function queryTradeList(data) {
    return request({
        url: "/label/trade/query",
        method: "post",
        data,
    })
}

// 根据分类的ID删除题库分类
export function deletedQueType(data) {
    return request({
        url: "/que/type/deleted/" + data.id,
        method: "delete"
    })
}

// 根据分类的ID删除题库分类
export function deletedQuestion(data) {
    return request({
        url: "/que/deleted/" + data.id,
        method: "delete"
    })
}

// 分页查询题库题目列表
export function queryPageQueList(data) {
    return request({
        url: "/que/que/record/query/page",
        method: "post",
        data,
    })
}

// 添加题目到题库
export function saveQuestion(data) {
    return request({
        url: "/que/save",
        method: "post",
        data,
    })
}

// 根据分类的ID删除题库分类
export function queryQuestion(data) {
    return request({
        url: "/que/query/by/" + data.id,
        method: "get"
    })
}

// 修改题目到题库
export function modifyQuestion(data) {
    return request({
        url: "/que/modify",
        method: "post",
        data,
    })
}

// 根据分类的ID获取分类信息
export function queryTypeInfo(data) {
    return request({
        url: "/que/type/query/" + data.id,
        method: "get"
    })
}

// 修改题库信息的内容
export function modifyQueType(data) {
    return request({
        url: "/que/type/info/modify",
        method: "post",
        data,
    })
}

// 修改题库信息的内容
export function queryPageCusOrgCus(data) {
    return request({
        url: "/cus/user/org/page/query",
        method: "post",
        data,
    })
}

// 获取当前用户的菜单列表
export function queryMenuListJsonByRoleId() {
    return request({
        url: "/sys/menu/query/json/id",
        method: "get"
    })
}

// 获取菜单的列表
export function queryMenuPage(data) {
    return request({
        url: "/sys/menu/menu/page/query",
        method: "post",
        data,
    })
}

// 根据分类的ID获取分类信息
export function queryListByType(data) {
    return request({
        url: "/sys/menu/list/query/" + data.type,
        method: "get"
    })
}

// 保存菜单数据
export function saveMenu(data) {
    return request({
        url: "/sys/menu/save",
        method: "post",
        data,
    })
}

// 根据菜单的ID获取菜单的信息
export function queryMenuInfo(data) {
    return request({
        url: "/sys/menu/info/query/" + data.menuId,
        method: "get"
    })
}

// 保存菜单信息
export function modifyMenu(data) {
    return request({
        url: "/sys/menu/modify",
        method: "post",
        data,
    })
}

// 获取会员等级信息
export function getMemberVipList(data) {
    return request({
        url: "/ord/rule/page/query",
        method: "post",
        data,
    })
}

// 修改会员等级信息
export function modifyMemberVip(data) {
    return request({
        url: "/ord/rule/modify",
        method: "post",
        data,
    })
}

// 保存会员等级信息
export function saveMemberVip(data) {
    return request({
        url: "/ord/rule/save",
        method: "post",
        data,
    })
}

// 删除会员等级信息
export function deletedMemberVip(data) {
    return request({
        url: "/ord/rule/deleted/" + data.ruleId,
        method: "delete"
    })
}

// 获取机构的角色
export function getOrgRoleList(data) {
    return request({
        url: "/sys/role/query",
        method: "post",
        data,
    })
}

// 获取机构的信息列表
export function getOrgInfoList(data) {
    return request({
        url: "/sys/org/page/" + data.pageNo + "/" + data.pageSize,
        method: "get",
        data,
    })
}

// 查询机构管理员的信息
export function getSysUserList(data) {
    return request({
        url: "/sys/user/page/" + data.pageNo + "/" + data.pageSize,
        method: "get",
        data,
    })
}

// 分页查询订单信息
export function getOrderInfoList(data) {
    return request({
        url: "/ord/page/query",
        method: "post",
        data,
    })
}

// 用户获取自己的权限
export function getMenusByRoleId() {
    return request({
        url: "/sys/menu/query",
        method: "get",
    });
}

// 保存用户的角色列表
export function saveRoleInfo(data) {
    return request({
        url: "/sys/role/save",
        method: "post",
        data,
    });
}

// 根据角色ID获取角色的信息
export function getRoleInfo(data) {
    return request({
        url: "/sys/role/query/" + data.id,
        method: "get",
    });
}

// 根据角色的ID修改角色的信息
export function modifyRoleInfo(data) {
    return request({
        url: "/sys/role/modify",
        method: "post",
        data,
    });
}

// 获取登陆机构角色列表
export function getRoleListByOrgId() {
    return request({
        url: "/sys/role/query/list",
        method: "get",
    });
}

// 查询机构信息的列表
export function getOrgListByOrgId() {
    return request({
        url: "/sys/org/query/org/info/list",
        method: "get",
    });
}

// 保存系统管理员
export function saveSysUserInfo(data) {
    return request({
        url: "/sys/user/save",
        method: "post",
        data,
    });
}

// 根据管理员的ID获取管理员的信息
export function getSysUserInfo(data) {
    return request({
        url: "/sys/user/user/info/query/" + data.id,
        method: "get",
    });
}

// 修改管理员的信息
export function updateSysUserInfo(data) {
    return request({
        url: "/sys/user/modify",
        method: "post",
        data,
    });
}

// 上传文件的接口
export function uploadData(data) {
    return request({
        url: "/open/que/import",
        method: "post",
        data,
    });
}

// 查询定时任务分类信息
export function queryTaskTypeList(data) {
    return request({
        url: "/job/page/type/query",
        method: "post",
        data,
    });
}

// 保存定时任务的分类信息
export function saveTaskTypeInfo(data) {
    return request({
        url: "/job/type/save",
        method: "post",
        data,
    });
}

// 修改定时任务的分类信息
export function updateTaskTypeInfo(data) {
    return request({
        url: "/job/type/modify",
        method: "post",
        data,
    });
}

// 查询任务列表
export function queryTaskList(data) {
    return request({
        url: "/job/page/query",
        method: "post",
        data,
    });
}

// 保存任务信息
export function saveTaskInfo(data) {
    return request({
        url: "/job/save",
        method: "post",
        data,
    });
}

// 修改任务信息
export function updateTaskInfo(data) {
    return request({
        url: "/job/modify",
        method: "post",
        data,
    });
}

// 修改任务信息的状态
export function updateTaskStatus(data) {
    return request({
        url: "/job/modify/status",
        method: "post",
        data,
    });
}

// 分页获取任务执行的记录
export function queryRecordList(data) {
    return request({
        url: "/job/record/page/query",
        method: "post",
        data,
    });
}

// 标签分类信息列表
export function queryLabelTypeList(data) {
    return request({
        url: "/label/type/query",
        method: "post",
        data,
    });
}

// 标签分类信息保存
export function saveLabelTypeInfo(data) {
    return request({
        url: "/label/type/save",
        method: "post",
        data,
    });
}

// 修改标签分类信息
export function updateLabelTypeInfo(data) {
    return request({
        url: "/label/type/modify",
        method: "post",
        data,
    });
}

// 标签信息列表
export function queryLabelList(data) {
    return request({
        url: "/label/page/query",
        method: "post",
        data,
    });
}

// 标签信息保存
export function saveLabelInfo(data) {
    return request({
        url: "/label/save",
        method: "post",
        data,
    });
}

// 修改标签信息
export function updateLabelInfo(data) {
    return request({
        url: "/label/modify",
        method: "post",
        data,
    });
}

// 获取视频分类的列表
export function queryVideoTypeList(data) {
    return request({
        url: "/vi/page/type/query",
        method: "post",
        data,
    });
}

// 保存视频分类的信息
export function saveVideoTypeInfo(data) {
    return request({
        url: "/vi/type/save",
        method: "post",
        data,
    });
}

// 修改视频分类的信息
export function updateVideoTypeInfo(data) {
    return request({
        url: "/vi/type/modify",
        method: "post",
        data,
    });
}

// 保存文章的内容
export function addArticleInfo(data) {
    return request({
        url: "/art/article/info/save",
        method: "post",
        data,
    });
}