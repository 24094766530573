<template>
  <el-container>
    <!--搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="会员价格:" style="padding-left: 4px">
          <el-input v-model="formInline.nickName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="行业分类:" style="padding-left: 4px">
          <el-input v-model="formInline.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="saveDrawer=true" style="width: 100px">添加规则</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--记录展示-->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="memberVipList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="orgName"
            label="所属机构"
            width="120">
        </el-table-column>
        <el-table-column
            prop="ruleName"
            label="名称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="price"
            label="原价(元)"
            width="120">
        </el-table-column>
        <el-table-column
            prop="discount"
            label="折扣"
            width="150">
        </el-table-column>
        <el-table-column
            prop="actualPrise"
            label="折后价(元)"
            width="150">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="300">
        </el-table-column>
        <el-table-column
            prop="ruleDes"
            label="规则描述"
            width="190">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120"
            style="text-align: center">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="checkRuleInfo(scope.$index, memberVipList)"
                @click="updateDrawer=true"
                type="text"
                size="small">
              查看
            </el-button>
            <el-button
                @click.native.prevent="deletedMemberRule(scope.$index, memberVipList)"
                type="text"
                size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 添加会员信息的弹窗 -->
    <el-drawer
        title="添加会员规则信息"
        :visible.sync="saveDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="规则名字:">
              <el-input v-model="form.ruleName"></el-input>
            </el-form-item>
            <el-form-item label="会员价格:">
              <el-input v-model="form.price"></el-input>
            </el-form-item>
            <el-form-item label="会员折扣:">
              <el-input v-model="form.discount"></el-input>
            </el-form-item>
            <el-form-item label="会员描述:">
              <el-input type="textarea" v-model="form.ruleDes"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="saveMemberRule" style="width: 300px">提交保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 修改会员信息的弹窗 -->
    <el-drawer
        title="修改会员规则的信息"
        :visible.sync="updateDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="updateForm" label-width="80px">
            <el-form-item label="规则名字:">
              <el-input v-model="updateForm.ruleName"></el-input>
            </el-form-item>
            <el-form-item label="会员价格:">
              <el-input v-model="updateForm.price"></el-input>
            </el-form-item>
            <el-form-item label="会员折扣:">
              <el-input v-model="updateForm.discount"></el-input>
            </el-form-item>
            <el-form-item label="会员描述:">
              <el-input type="textarea" v-model="updateForm.ruleDes"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="modifyMemberRule" style="width: 300px">提交保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!--分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
// 引入获取信息的接口
import {deletedMemberVip, getMemberVipList, modifyMemberVip, saveMemberVip} from "../../../module/reqapi/routepath";

// 关于JS相关的操作
export default {
  name: "userVipLevel",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        nickName: '',
        phone: '',
        isVip: '',
        status: ''
      },
      memberVipList: [],
      form: {
        ruleName: '',
        ruleDes: '',
        price: '',
        discount: ''
      },
      updateForm: {
        ruleName: '',
        ruleDes: '',
        price: '',
        discount: '',
        ordRuleId: ''
      },
      updateDrawer: false,
      saveDrawer: false
    }
  },
  created() {
    this.getMemberLevelList()
  },
  methods: {
    // 获取会员等级的设置集合
    getMemberLevelList() {
      getMemberVipList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.memberVipList = res.data.list
          this.count = res.data.count
        }
      })
    },
    // 查看会员规则的信息
    checkRuleInfo(index, rows) {
      this.updateForm = rows[index];
    },
    // 修改角色的信息
    modifyMemberRule() {
      modifyMemberVip({
        ruleName: this.updateForm.ruleName,
        ruleDes: this.updateForm.ruleDes,
        price: this.updateForm.price,
        discount: this.updateForm.discount,
        ordRuleId: this.updateForm.ordRuleId
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.getMemberLevelList();
          this.updateDrawer = false;
        }
      })
    },
    // 保存会员规则信息
    saveMemberRule(){
      saveMemberVip({
        ruleName: this.form.ruleName,
        ruleDes: this.form.ruleDes,
        price: this.form.price,
        discount: this.form.discount
      }).then((res)=>{
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.getMemberLevelList();
          this.saveDrawer = false;
        }
        console.log(res)
      })
    },
    // 删除会员规则信息
    deletedMemberRule(index, rows){
      deletedMemberVip({
        ruleId:rows[index].ordRuleId,
      }).then((res)=>{
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getMemberLevelList();
        }
      })
    },
    // 多条件查询
    onSubmit(){
      this.getMemberLevelList()
    }
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

.drawer-style {
  height: 75%;
  margin-top: 160px;
  margin-right: 400px;
  margin-left: 400px;
  border-radius: 10px;
  text-align: center;
}

.drawer-box {
  width: 100%;
  height: 100%;
  background: #F4F4F4;
}

/deep/ .el-drawer__header {
  background: #F4F4F4;
  font-size: 16px;
  margin-bottom: 0.5px;
  padding: 10px 10px;
}

.addTaskType {
  margin-left: 200px;
  padding-top: 80px;
}


</style>