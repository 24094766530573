<template>
  <el-container>
    <!--搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="角色名:" style="padding-left: 4px">
          <el-input v-model="formInline.roleName" placeholder="请输入角色名"></el-input>
        </el-form-item>
        <el-form-item label="所属行业:" style="padding-left: 4px">
          <el-input v-model="formInline.tradeId" placeholder="请选择所属行业"></el-input>
        </el-form-item>
        <el-form-item label="角色分类:" style="padding-left: 4px">
          <el-select v-model="formInline.type" placeholder="角色分类">
            <el-option label="客户端" value="0"></el-option>
            <el-option label="WEB端" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--记录展示-->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="orgInfoList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="showName"
            label="机构简称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="orgNumber"
            label="机构编号"
            width="150">
        </el-table-column>
        <el-table-column
            prop="level"
            label="层级"
            width="100">
        </el-table-column>
        <el-table-column
            prop="latitude"
            label="纬度"
            width="100">
        </el-table-column>
        <el-table-column
            prop="longitude"
            label="经度"
            width="100">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="200">
        </el-table-column>
        <el-table-column
            prop="address"
            label="详细地址"
            width="220">
        </el-table-column>
        <el-table-column
            prop="orgName"
            label="机构全称"
            width="220">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="modifyQuestion(scope.$index, roleList)"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!--分页展示-->
    <el-footer  style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
// 引入获取信息的接口
import {getOrgInfoList} from "../../../module/reqapi/routepath";

// 关于JS相关的操作
export default {
  name: "orgManager",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        roleName: '',
        tradeId: '',
        type: '',
      },
      orgInfoList: []
    }
  },
  created() {
    this.getOrgInfoList()
  },
  methods: {
    // 获取会员等级的设置集合
    getOrgInfoList() {
      getOrgInfoList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.orgInfoList = res.data.list
          this.count = res.data.count
        }
      })
    }

  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

</style>