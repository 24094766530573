<template>

</template>

<script>
export default {
  name: "Index",
  created() {
    this.goComIndexPage();
  },
  methods: {
    goComIndexPage() {
      this.$router.push({
        path: "/comIndex"
      })
    }
  }
}
</script>

<style>


</style>