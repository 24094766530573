<template>
  <el-container>
    <!--搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="角色名:" style="padding-left: 4px">
          <el-input v-model="formInline.roleName" placeholder="请输入角色名"></el-input>
        </el-form-item>
        <el-form-item label="所属行业:" style="padding-left: 10px">
          <el-select v-model="formInline.tradeId" placeholder="选择行业分类">
            <el-option
                v-for="item in tradeList"
                :key="item.tradeId"
                :label="item.tradeName"
                :value="item.tradeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色分类:" style="padding-left: 4px">
          <el-select v-model="formInline.type" placeholder="角色分类">
            <el-option label="客户端" value="0"></el-option>
            <el-option label="WEB端" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="addSysRole" style="width: 100px;">添加角色</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--添加角色-->

    <!--修改角色-->
    <!--记录展示-->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="roleList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="orgName"
            label="所属机构"
            width="120">
        </el-table-column>
        <el-table-column
            prop="roleName"
            label="名称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="roleCode"
            label="编码"
            width="120">
        </el-table-column>
        <el-table-column
            prop="tradeName"
            label="所属行业"
            width="150">
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="角色分类"
            width="150">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="权限类目"
            width="490">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="modifyRoleList(scope.$index, roleList)"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!--分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
// 引入获取信息的接口
import {getOrgRoleList, queryTradeList} from "../../../module/reqapi/routepath";

// 关于JS相关的操作
export default {
  name: "roleManager",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        roleName: '',
        tradeId: '',
        type: '',
      },
      form: {},
      updateForm: {},
      roleList: [],
      tradeList: []
    }
  },
  created() {
    this.getOrgRoleList()
    this.getTradeList()
  },
  methods: {
    // 获取会员等级的设置集合
    getOrgRoleList() {
      getOrgRoleList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        roleName: this.formInline.roleName,
        tradeId: this.formInline.tradeId,
        type: this.formInline.type
      }).then((res) => {
        if (res.code == 200) {
          this.roleList = res.data.list
          this.count = res.data.count
        }
      })
    },
    // 获取行业分类的列表
    getTradeList() {
      queryTradeList({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then((req) => {
        if (req.code == 200) {
          this.tradeList = req.data;
        }
        console.log(this.tradeList)
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.getTypeList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.getTypeList()
    },
    // 条件查询角色列表
    onSubmit() {
      this.getOrgRoleList()
    },
    // 修改角色的权限集
    modifyRoleList(index, rows) {
      this.$router.push({
        path: "/modifyRole?roleId=" + rows[index].roleId
      })
    },
    // 添加系统的角色
    addSysRole() {
      this.$router.push({
        path: "/addRole"
      })
    }
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

</style>