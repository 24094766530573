<template>
  <el-container>
    <!-- 文章编辑的背景 -->
    <div class="art_back">
      <el-form class="art_edit">
        <div class="art_top_box">
          <div style="height:100%; width: 50%; padding-top: 10px">
            <el-form-item label="文章标题:" prop="articleName" :verify="{ maxLen: 30 }">
              <el-input v-model="form.articleName" style="width: 85%;" placeholder="输入文章标题"></el-input>
            </el-form-item>
          </div>
          <div style="height:100%; width: 30%; padding-top: 10px; padding-left: 10px">
            <el-form-item label="文章分类:" prop="artType" :verify="{ maxLen: 30 }">
              <el-select v-model="form.artType" placeholder="文章分类:" style="width: 70%;">
                <el-option label="单选" value="1"></el-option>
                <el-option label="多选" value="2"></el-option>
                <el-option label="其他" value="3"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="height:100%; width: 20%; padding-top: 10px">
            <el-button type="primary" style="width: 46%;" @click="addArticleInfo">保存草稿</el-button>
            <el-button type="success" style="width: 46%;">保存文章</el-button>
          </div>
        </div>
        <div class="art_intro_box">
          <div style="height:100%; width: 100%; padding-top: 10px">
            <el-form-item label="文章简介:" prop="artIntroduce" :verify="{ maxLen: 30 }">
              <el-input v-model="form.artIntroduce" style="width: 92%;" placeholder="输入文章简介"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="art_edit_box">
          <MiniMCE style="width:100%;" v-model="form.articleInfo"/>
        </div>
      </el-form>
    </div>
  </el-container>
</template>

<script>
// 导入查询的方法
import {
  addArticleInfo
} from "../../../module/reqapi/routepath";

export default {
  name: "articleEdit",
  data() {
    return {
      form: {
        articleName: '',
        artType: '',
        artIntroduce: '',
        articleInfo: ''
      },
    };
  },
  methods: {
    addArticleInfo() {
      addArticleInfo({
        artName: this.form.articleName,
        artType: this.form.artType,
        artIntroduce: this.form.artIntroduce,
        articleInfo: this.form.articleInfo,
        artStatus: 0
      }).then((req) => {
        if (req.code == 200) {
          this.$message.success("保存成功");
        }
      })
      console.log(this.form);
    }
  }
}
</script>

<style lang="scss" scoped>

.art_back {
  height: 88%;
  width: 86%;
  position: fixed;
  background: #fff;
  box-shadow: 0 -3px 5px #eee;
}

.art_edit {
  height: 88%;
  width: 70%;
  margin-left: 15%;
  background: #ffffff;
}

.art_top_box {
  height: 60px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  box-shadow: 0px 0px 0px 1px #f8f6f6;
  //background: #B3C0D1;
}

.art_intro_box {
  height: 60px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  box-shadow: 0px 0px 0px 1px #f8f6f6;
}

.art_edit_box {
  height: 700px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  box-shadow: 0px 0px 0px 1px #f8f6f6;
}

/deep/ .tox-edit-area {
  height: 582px;
}

/deep/ .tox-sidebar-wrap {
  height: 582px;
}

</style>