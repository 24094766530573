<template>
  <div class="indexPage">
    <el-container class="updateRole">
      <el-form :model="form" label-position="labelPosition" label-width="80px">
        <el-form-item label="角色名称:">
          <el-input v-model="form.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色编码:">
          <el-input v-model="form.roleCode"></el-input>
        </el-form-item>
        <el-form-item label="所属行业:">
          <el-select v-model="form.tradeId" placeholder="选择行业分类" style="width: 100%">
            <el-option
                v-for="item in tradeList"
                :key="item.tradeId"
                :label="item.tradeName"
                :value="item.tradeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色分类:">
          <el-select v-model="form.type" placeholder="默认分类" style="width: 100%">
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.typeName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色权限:">
          <el-tree
              :data="menuList"
              show-checkbox
              node-key="menuId"
              ref="tree"
              highlight-current
              :default-checked-keys="checkIds"
              :props="defaultProps"
              @check="(click, checked)=>{handleCheckChange(click, checked,index)}">
          </el-tree>
        </el-form-item>
        <el-form-item label="角色描述:">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="onSubmit" style="width: 100px">修改角色</el-button>
          <el-button type="danger" @click="goRoleList" style="width: 100px">返回列表</el-button>
        </el-form-item>
      </el-form>
    </el-container>
  </div>
</template>

<script>
// 引入获取信息的接口
import {getMenusByRoleId, getRoleInfo, modifyRoleInfo, queryTradeList} from "../../../module/reqapi/routepath";

export default {
  name: "modifyRole",
  data() {
    return {
      form: {
        roleName: '',
        roleCode: '',
        tradeId: '',
        type: '',
        remark: ''
      },
      menuList: [],
      checkIds: [],
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },
      pageNo: 1,
      pageSize: 10,
      tradeList: [],
      menuIds: [],
      typeList: [
        {
          id: 0,
          typeName: '客户端'
        },
        {
          id: 1,
          typeName: 'WEB端'
        }
      ]
    }
  },
  created() {
    this.getMenus()
    this.getRoleInfoByRoleId()
    this.getTradeList()
  },
  methods: {
    // 获取角色的权限集
    getMenus() {
      getMenusByRoleId().then((res) => {
        console.log(res)
        this.menuList = res.data
      })
    },
    // 获取选择的节点数组
    handleCheckChange(click, checked, index) {
      console.log(checked)
      console.log(checked.checkedKeys, index);//获取全选数组
      this.menuIds = checked.checkedKeys;
    },
    // 获取行业分类的列表
    getTradeList() {
      queryTradeList({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then((req) => {
        if (req.code == 200) {
          this.tradeList = req.data;
        }
        console.log(this.tradeList)
      })
    },
    // 修改角色的信息
    onSubmit() {
      modifyRoleInfo({
        roleId: this.$route.query.roleId,
        roleName: this.form.roleName,
        roleCode: this.form.roleCode,
        remark: this.form.remark,
        type: this.form.type,
        menuIds: this.menuIds
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.goRoleList()
        }
      })
    },
    // 跳回到角色的列表
    goRoleList() {
      this.$router.push({
        path: "/roleManager"
      })
    },
    // 根据角色的ID获取角色的信息
    getRoleInfoByRoleId() {
      getRoleInfo({
        id: this.$route.query.roleId
      }).then((res) => {
        if (res.code == 200) {
          this.form = res.data
          this.checkIds = res.data.menuIds
        }
        console.log(res)
      })
    }
  }
}
</script>

<style scoped>


.indexPage {
  width: 86.6%;
  height: 88%;
}

.el-form {
  width: 100%;
}

.updateRole {
  width: 30%;
  height: 70%;
  margin-left: 34%;
  margin-top: 5%;
  text-align: center;
}

</style>