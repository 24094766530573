<template>
  <el-container class="container">
    <!-- 头部 -->
    <el-header>
      <div>
        <table style="height: 20px;">
          <tr style="width: 10%">
            <td style="margin-left: 30px"><img
                src="https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/favicon.png" height="23px"
                width="23px" style="margin-top: 4px"></td>
            <td><span style="font-size: 15px">企业后台管理系统</span></td>
            <td style="width: 1400px"></td>
            <td>
              <div class="grid-content bg-purple" @click="goUserCenter">
                <div class="demo-type" style="height: 50px;padding-top: 7px">
                  <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </el-header>
    <!-- 导航栏 -->
    <el-container>
      <el-aside class="aside" style="height: 930px;  width: 200px; padding-top: 60px">
        <div>
          <el-menu
              background-color="#FFF"
              text-color="#303133"
              active-text-color="#409EFF"
              :default-active="activeIndex"
              router
              style="width:190px;float:left">
            <NavMenu :navMenus="menuData"></NavMenu>
          </el-menu>
        </div>
        <div class="getOut c_pointer" @click="LoginOut">
          <el-icon class="el-icon-switch-button"></el-icon>
          <span slot="title">退出登录</span>
        </div>
      </el-aside>
      <!-- 主内容 -->
      <el-main class="main">
        <el-dialog :close-on-click-modal="false" title="友情提示" :visible.sync="dialogVisible" top="30vh" width="30%">
          <div>您确定要退出账号吗？</div>
          <span slot="footer" class="dialog-footer">
          		<el-button type="goon2" @click="dialogVisible = false">取 消</el-button>
          		<el-button type="goon" @click="confirmOut">确 定</el-button>
          	</span>
        </el-dialog>
        <el-scrollbar style="width: 100%"></el-scrollbar>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// 引入菜单的模版
import NavMenu from "./module/NavMenu.vue";
// 调用清除token的方法
import {localRemove} from "../../module/reqapi/token.js";
import {queryMenuListJsonByRoleId} from "../../module/reqapi/routepath";

export default {
  components: {
    NavMenu: NavMenu,
  },
  // 页面数据
  data() {
    return {
      dialogVisible: false,
      activeIndex: 'backIndex',
      menuData: []
    }
  },

  created() {
    this.getMenuData()
  },

  mounted() {
    let start = window.location.href.lastIndexOf('/');
    let path = window.location.href.slice(start + 1);
    this.activeIndex = path;
  },

  // 页面的方法
  methods: {
    // 退出登陆
    LoginOut() {
      this.dialogVisible = true;
    },
    //
    confirmOut() {
      localRemove();
      sessionStorage.clear();
      this.$router.push({
        path: "/login"
      })
      this.dialogVisible = false;
    },
    // 去个人中心
    goUserCenter() {
      this.$router.push({
        path: "/userCenter"
      })
    },
    // 获取用户的菜单数据
    getMenuData() {
      queryMenuListJsonByRoleId().then((res) => {
        if (res.code == 200) {
          this.menuData = res.data
        }
      })
    }
  }
}
</script>

<style>

.el-header {
  position: fixed;
  height: 50px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
}

.main {
  background: #f5f4f4;
  padding-left: 6px;
  border-radius: 4px;
  text-align: left;
  margin-top: 48px;
  width: 100%;
}

.container {
  width: 100%;
  border-radius: 4px;
}

.el-menu {
  border-right: 0 !important;
}

.bg-purple {
  background-color: #fff;
}

.indexPage {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: fixed;
  background-color: #fff;
  border-radius: 4px;
}

.getOut {
  position: fixed;
  top: 94%;
  margin: 0 0 0 2.1rem;
  font-size: 15px;
  color: #303133;
}

.v-modal {
  position: center;
}


</style>