import axios from "axios";
import {Message, MessageBox} from "element-ui";
import {localGet, localRemove} from "./token.js";
import Router from "vue-router";
import Vue from "vue";
import homeUrl from "./baseurl.js";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
});

const Server = axios.create({
    baseURL: homeUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
    },
});

//使用Server创建请求拦截器
Server.interceptors.request.use(
    function (config) {
        if (localGet()) {
            config.headers.Authorization = localGet();
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
//使用Server创建响应拦截器
Server.interceptors.response.use(
    function (response) {
        //判断接口返回的数据成功，直接返回数据中data数据
        if (response.data.code === 401) {
            Message.error(response.data.message);
            localRemove();
            router.replace({path: "/login"});
            location.reload();
        }
        if (response.data.code === 200) {
            return response.data;
        } else {
            Message.error(response.data.msg);
        }
    },
    function (error) {
        return Promise.reject(error);
    }
);
export default Server;
