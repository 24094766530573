import Vue from 'vue'
import Router from 'vue-router';
import Login from '../components/back/backlogin/login';
import Index from "../components";
import Register from '../components/company/register';
import BackIndex from '../components/back/backIndex';
import QuestionBank from "../components/back/question/questionBank";
import QuestionType from "../components/back/question/questionType";
import OrgManager from "../components/back/backadmin/orgManager";
import UserCenter from "../components/back/backadmin/userCenter";
import UserManager from "../components/back/backadmin/userManager";
import RoleManager from "../components/back/backadmin/roleManager";
import MemberList from "../components/back/member/memberList";
import MenuManager from "../components/back/backadmin/menuManager";
import UserVipLevel from "../components/back/member/userVipLevel";
import LabelManager from "../components/back/backlabel/labelManager";
import TaskManager from "../components/back/backtask/taskManager";
import OrderList from "../components/back/backorder/orderList";
import ModifyRole from "../components/back/backadmin/modifyRole";
import AddRole from "../components/back/backadmin/addRole";
import ComIndex from "../components/company/comIndex";
import ComShow from "../components/company/comShow";
import CustomerCase from "../components/company/customerCase";
import NewsShow from "../components/company/newsShow";
import CompanyInfo from "../components/company/companyInfo";
import TaskTypeManager from "../components/back/backtask/taskTypeManager";
import TaskRecord from "../components/back/backtask/taskRecord";
import VideoList from "../components/back/backvideo/videoList";
import VideoType from "../components/back/backvideo/videoType";
import LabelType from "../components/back/backlabel/labelType";
import ArticleType from "../components/back/backarticle/articleType";
import ArticleList from "../components/back/backarticle/articleList";
import ArticleEdit from "../components/back/backarticle/articleEdit";

Vue.use(Router)
const routes = [
    {path: '/', name: 'Index', component: Index},
    {
        path: '/comIndex', name: 'ComIndex', component: ComIndex,
        children: [
            {path: '/register', name: 'Register', component: Register},
            {path: '/comShow', name: 'ComShow', component: ComShow},
            {path: '/customerCase', name: 'CustomerCase', component: CustomerCase},
            {path: '/newsShow', name: 'NewsShow', component: NewsShow},
            {path: '/companyInfo', name: 'CompanyInfo', component: CompanyInfo},
        ]
    },
    {path: '/login', name: 'Login', component: Login},
    {
        path: '/backIndex', name: 'BackIndex', component: BackIndex,
        children: [
            {path: '/questionBank', name: 'QuestionBank', component: QuestionBank},
            {path: '/questionType', name: 'QuestionType', component: QuestionType},
            {path: '/orgManager', name: 'OrgManager', component: OrgManager},
            {path: '/userCenter', name: 'UserCenter', component: UserCenter},
            {path: '/userManager', name: 'UserManager', component: UserManager},
            {path: '/roleManager', name: 'RoleManager', component: RoleManager},
            {path: '/memberList', name: 'MemberList', component: MemberList},
            {path: '/menuManager', name: 'MenuManager', component: MenuManager},
            {path: '/userVipLevel', name: 'UserVipLevel', component: UserVipLevel},
            {path: '/labelManager', name: 'LabelManager', component: LabelManager},
            {path: '/taskManager', name: 'TaskManager', component: TaskManager},
            {path: '/orderList', name: 'OrderList', component: OrderList},
            {path: '/modifyRole', name: 'ModifyRole', component: ModifyRole},
            {path: '/addRole', name: 'AddRole', component: AddRole},
            {path: '/taskTypeManager', name: 'TaskTypeManager', component: TaskTypeManager},
            {path: '/taskRecord', name: 'TaskRecord', component: TaskRecord},
            {path: '/videoList', name: 'VideoList', component: VideoList},
            {path: '/videoType', name: 'VideoType', component: VideoType},
            {path: '/labelType', name: 'LabelType', component: LabelType},
            {path: '/articleType', name: 'ArticleType', component: ArticleType},
            {path: '/articleList', name: 'ArticleList', component: ArticleList},
            {path: '/articleEdit', name: 'ArticleEdit', component: ArticleEdit}
        ]
    },
]
export default routes
