<template>
<!--  <div class="indexPage">{{userinfo.name}}</div>-->
</template>

<script>
// 获取用户的信息
import {getUserInfo} from "../../../module/reqapi/routepath";

export default {
  name: "userCenter",
  //  数据
  data() {
    return {
      userinfo: {},
    }
  },
  // 进入页面初始化数据
  mounted() {
    this.queryUserInfo();
  },
  // 页面相关的方法
  methods: {
    queryUserInfo() {
      getUserInfo().then((rep) => {
        this.userinfo = rep.data;
      })
    }
  }
}
</script>

<style scoped>

</style>