<template>

</template>

<script>
export default {
  name: "videoList",
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>