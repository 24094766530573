<template>
  <el-container>
    <!-- 搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="文章标题:" style="padding-left: 4px">
          <el-select v-model="formInline.typeId" placeholder="选择标签分类">
            <el-option
                v-for="item in typeList"
                :key="item.labelTypeId"
                :label="item.typeName"
                :value="item.labelTypeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章分类:" style="padding-left: 4px">
          <el-input v-model="formInline.labelName" placeholder="请输入标签的名字"></el-input>
        </el-form-item>
        <el-form-item label="文章内容:" style="padding-left: 4px">
          <el-input v-model="formInline.labelDes" placeholder="请输入标签描述"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="goArticleEdit" style="width: 100px">添加文章</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-container>
</template>

<script>

export default {
  name: "articleList",
  data() {
    return {
      formInline: {
        typeName: '',
        tradeId: ''
      },
    }
  },
  methods: {
    // 跳转到文章编辑到页面
    goArticleEdit() {
      this.$router.push({
        path: "/articleEdit"
      })
    }
  }

}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}
</style>