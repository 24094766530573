<template>
  <el-container>
    <!-- 搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="所属机构:">
          <el-select v-model="formInline.orgId" placeholder="选择所属机构">
            <!--              <el-option-->
            <!--                  v-for="item in typeList"-->
            <!--                  :key="item.queTypeId"-->
            <!--                  :label="item.typeName"-->
            <!--                  :value="item.queTypeId">-->
            <!--              </el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item label="菜单分类:" style="padding-left: 8px">
          <el-select v-model="formInline.parentId" placeholder="选择题目库分类">
            <el-option
                v-for="item in menuParentList"
                :key="item.menuId"
                :label="item.menuName"
                :value="item.menuId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称:" style="padding-left: 8px">
          <el-input v-model="formInline.menuName" placeholder="请输菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单分类:" style="padding-left: 8px">
          <el-select v-model="formInline.type" placeholder="默认分类">
            <el-option label="管理后台" value="0"></el-option>
            <el-option label="小程序" value="1"></el-option>
            <el-option label="其他" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="saveDrawer=true" style="width: 100px;">添加菜单</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加菜单-->
    <el-drawer
        title="添加菜单信息"
        :visible.sync="saveDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="菜单名称:">
              <el-input v-model="form.menuName"></el-input>
            </el-form-item>
            <el-form-item label="菜单父级:">
              <el-select v-model="form.parentId" placeholder="请选菜单分类" style="width: 320px">
                <el-option
                    v-for="item in menuParentList"
                    :key="item.menuId"
                    :label="item.menuName"
                    :value="item.menuId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜单图标:">
              <el-input v-model="form.icon"></el-input>
            </el-form-item>
            <el-form-item label="菜单路由:">
              <el-input v-model="form.url"></el-input>
            </el-form-item>
            <el-form-item label="菜单分类:">
              <el-select v-model="form.type" placeholder="默认分类" style="width: 320px">
                <el-option label="管理后台" value="0"></el-option>
                <el-option label="小程序" value="1"></el-option>
                <el-option label="其他" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜单权重:">
              <el-input v-model="form.weight"></el-input>
            </el-form-item>
            <el-form-item label="菜单备注:">
              <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="addSubmit" style="width: 300px">添加菜单</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 修改菜单-->
    <el-drawer
        title="修改菜单信息"
        :visible.sync="updateDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="updateForm" label-width="80px">
            <el-form-item label="菜单名称:">
              <el-input v-model="updateForm.menuName"></el-input>
            </el-form-item>
            <el-form-item label="菜单父级:">
              <el-select v-model="updateForm.parentId" placeholder="请选菜单分类" style="width: 320px">
                <el-option
                    v-for="item in menuParentList"
                    :key="item.menuId"
                    :label="item.menuName"
                    :value="item.menuId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜单图标:">
              <el-input v-model="updateForm.icon"></el-input>
            </el-form-item>
            <el-form-item label="菜单路由:">
              <el-input v-model="updateForm.url"></el-input>
            </el-form-item>
            <el-form-item label="菜单分类:">
              <el-select v-model="updateForm.type" placeholder="默认分类" style="width: 320px">
                <el-option
                    v-for="item in options"
                    :key="item.typeId"
                    :label="item.typeName"
                    :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜单权重:">
              <el-input v-model="updateForm.weight"></el-input>
            </el-form-item>
            <el-form-item label="菜单备注:">
              <el-input type="textarea" v-model="updateForm.remark"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="updateMenuInfo" style="width: 300px">修改菜单</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 记录展示 -->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="menuList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="parentName"
            label="所属菜单"
            width="100">
        </el-table-column>
        <el-table-column
            prop="menuName"
            label="菜单名称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="english"
            label="英文名"
            width="150">
        </el-table-column>
        <el-table-column
            prop="isVip"
            label="付费标识(VIP)"
            width="110">
        </el-table-column>
        <el-table-column
            prop="status"
            label="菜单状态"
            width="110">
        </el-table-column>
        <el-table-column
            prop="icon"
            label="图标"
            width="250">
        </el-table-column>
        <el-table-column
            prop="url"
            label="路由地址"
            width="300">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注"
            width="80">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="modifyMenuInfo(scope.$index, menuList)"
                @click="updateDrawer=true"
                type="text"
                size="small">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>

</template>

<script>
import {modifyMenu, queryListByType, queryMenuInfo, queryMenuPage, saveMenu} from "../../../module/reqapi/routepath";

export default {
  name: "menuManager",
  created() {
    this.getListMenu()
    this.getParentMenuList()
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      count: 0,
      radio: 1,
      formInline: {
        type: '',
        menuName: '',
        parentId: ''
      },
      form: {
        menuName: '',
        parentId: '',
        icon: '',
        url: '',
        type: '',
        weight: 0,
        remark: ''
      },
      updateForm: {
        menuId: '',
        menuName: '',
        parentId: '',
        icon: '',
        url: '',
        type: '',
        weight: 0,
        remark: ''
      },
      options: [
        {
          typeId: 0,
          typeName: '管理后台'
        },
        {
          typeId: 1,
          typeName: '小程序'
        },
        {
          typeId: 2,
          typeName: '其他'
        }
      ],
      menuList: [],
      menuParentList: [],
      saveDrawer: false,
      updateDrawer: false
    }

  },
  methods: {
    // 分页获取菜单列表
    getListMenu() {
      queryMenuPage({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        type: this.formInline.type,
        menuName: this.formInline.menuName,
        parentId: this.formInline.parentId
      }).then((res) => {
        if (res.code == 200) {
          this.menuList = res.data.list;
          this.count = res.data.count;
          console.log(res.data.list)
        }
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.getListMenu()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.getListMenu()
    },
    // 根据条件查询
    onSubmit() {
      this.getListMenu()
    },
    // 添加菜单
    addSubmit() {
      saveMenu({
        parentId: this.form.parentId,
        menuName: this.form.menuName,
        type: this.form.type,
        icon: this.form.icon,
        url: this.form.url,
        weight: this.form.weight,
        remark: this.form.remark
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.saveDrawer = false;
          this.getListMenu()
        }
      })
    },
    // 获取分类的父子
    getParentMenuList() {
      queryListByType({
        type: this.count
      }).then((res) => {
        this.menuParentList = res.data
        console.log(res.data)
      })
    },
    // 查询菜单的详情
    modifyMenuInfo(index, rows) {
      queryMenuInfo({
        menuId: rows[index].menuId
      }).then((res) => {
        if (res.code == 200) {
          this.updateForm = res.data
        }
      })
    },
    updateMenuInfo() {
      let allList = this.updateForm
      modifyMenu(allList).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.updateDrawer = false;
          this.getListMenu()
        }
      })
    }

  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

.drawer-style {
  height: 75%;
  margin-top: 160px;
  margin-right: 400px;
  margin-left: 400px;
  border-radius: 10px;
  text-align: center;
}

.drawer-box {
  width: 100%;
  height: 100%;
  background: #F4F4F4;
}

/deep/ .el-drawer__header {
  background: #F4F4F4;
  font-size: 16px;
  margin-bottom: 0.5px;
  padding: 10px 10px;
}

.addTaskType {
  margin-left: 200px;
  padding-top: 80px;
}

</style>