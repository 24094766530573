<template>
  <el-container>
    <!-- 搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="任务名称:" style="padding-left: 4px">
          <el-input v-model="formInline.jobName" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="表达式:" style="padding-left: 4px">
          <el-input v-model="formInline.jobCron" placeholder="请输入任务表达式"></el-input>
        </el-form-item>
        <el-form-item label="任务状态:" style="padding-left: 4px">
          <el-select v-model="formInline.jobStatus" placeholder="选择任务状态">
            <el-option
                v-for="item in options"
                :key="item.queTypeId"
                :label="item.typeName"
                :value="item.queTypeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务分类:" style="padding-left: 4px">
          <el-select v-model="formInline.conTypeId" placeholder="请选择任务分类">
            <el-option
                v-for="item in taskTypeList"
                :key="item.typeId"
                :label="item.typeName"
                :value="item.typeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="saveDrawer=true" style="width: 100px">添加任务</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加定时任务分类的弹窗 -->
    <el-drawer
        title="添加定时任务信息"
        :visible.sync="saveDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="任务名字:">
              <el-input v-model="form.jobName"></el-input>
            </el-form-item>
            <el-form-item label="表达式:">
              <el-input v-model="form.jobCron"></el-input>
            </el-form-item>
            <el-form-item label="任务方法:">
              <el-input v-model="form.jobMethod"></el-input>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select v-model="form.jobStatus" placeholder="选择任务状态" style="width: 320px">
                <el-option
                    v-for="item in options"
                    :key="item.queTypeId"
                    :label="item.typeName"
                    :value="item.queTypeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务分类:">
              <el-select v-model="form.conTypeId" placeholder="请选择任务分类" style="width: 320px">
                <el-option
                    v-for="item in taskTypeList"
                    :key="item.typeId"
                    :label="item.typeName"
                    :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="其他配置:">
              <el-input type="textarea" v-model="form.jopConfig"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="saveTaskInfo" style="width: 300px">提交保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 修改定时任务分类的弹窗 -->
    <el-drawer
        title="修改任务信息"
        :visible.sync="updateDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="updateForm" label-width="80px">
            <el-form-item label="任务名字:">
              <el-input v-model="updateForm.jobName"></el-input>
            </el-form-item>
            <el-form-item label="表达式:">
              <el-input v-model="updateForm.jobCron"></el-input>
            </el-form-item>
            <el-form-item label="任务方法:">
              <el-input v-model="updateForm.jobMethod"></el-input>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select v-model="updateForm.jobStatus" placeholder="选择任务状态" style="width: 320px">
                <el-option
                    v-for="item in options"
                    :key="item.queTypeId"
                    :label="item.typeName"
                    :value="item.queTypeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务分类:">
              <el-select v-model="updateForm.conTypeId" placeholder="请选择任务分类" style="width: 320px">
                <el-option
                    v-for="item in taskTypeList"
                    :key="item.typeId"
                    :label="item.typeName"
                    :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="其他配置:">
              <el-input type="textarea" v-model="updateForm.jopConfig"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="modifyTaskInfo" style="width: 300px">修改保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 记录展示 -->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="taskList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="conTypeName"
            label="分类名称"
            width="100">
        </el-table-column>
        <el-table-column
            prop="jobName"
            label="任务名称"
            width="200">
        </el-table-column>
        <el-table-column
            prop="jobCron"
            label="表达式"
            width="120">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="200">
        </el-table-column>
        <el-table-column
            prop="jobMethod"
            label="任务方法"
            width="160">
        </el-table-column>
        <el-table-column
            prop="jopConfig"
            label="其他配置"
            width="300">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="状态"
            align="center"
            width="50">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.jobStatus == 0? '未开启':'进行中'" palacement="top">
              <el-switch
                  v-model="scope.row.jobStatus"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="changeJobStatus(scope.row.jobStatus,scope.row.conId)">
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="updateTaskInfo(scope.$index, taskList)"
                @click="updateDrawer=true"
                type="text"
                size="small" style="margin-left: 8px">
              查看
            </el-button>
            <el-button
                @click.native.prevent="goTaskRecord(scope.$index, taskList)"
                type="text"
                size="small">
              记录
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
import {
  queryTaskList,
  queryTaskTypeList,
  saveTaskInfo,
  updateTaskInfo,
  updateTaskStatus
} from "../../../module/reqapi/routepath";

export default {
  name: "taskManager",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        jobName: '',
        jobStatus: '',
        jobCron: '',
        conTypeId: ''
      },
      form: {
        jobName: '',
        jobCron: '',
        jobMethod: '',
        jobStatus: 1,
        conTypeId: '',
        jopConfig: ''
      },
      updateForm: {
        conId: '',
        jobName: '',
        jobCron: '',
        jobMethod: '',
        jobStatus: 1,
        conTypeId: '',
        jopConfig: ''
      },
      saveDrawer: false,
      updateDrawer: false,
      taskTypeList: [],
      options: [
        {
          queTypeId: 0,
          typeName: '进行中'
        },
        {
          queTypeId: 1,
          typeName: '关闭中'
        }
      ],
      taskList: []
    }
  },
  created() {
    this.queryTaskTypeList()
    this.queryTaskList()
  },
  methods: {
    // 修改任务的状态
    changeJobStatus(jobStatus, conId) {
      updateTaskStatus({
        conId: conId,
        jobStatus: jobStatus,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.queryTaskList();
        }
      })
    },
    // 修改定时任务分类信息
    updateTaskInfo(index, rows) {
      this.updateForm = rows[index];
    },
    modifyTaskInfo() {
      updateTaskInfo({
        conId: this.updateForm.conId,
        jobName: this.updateForm.jobName,
        jobCron: this.updateForm.jobCron,
        jobMethod: this.updateForm.jobMethod,
        jobStatus: this.updateForm.jobStatus,
        conTypeId: this.updateForm.conTypeId,
        jopConfig: this.updateForm.jopConfig,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.queryTaskList();
          this.updateDrawer = false;
        }
      })
    },
    // 获取定时任务的分类信息
    queryTaskTypeList() {
      queryTaskTypeList({
        pageSize: 100,
        pageNo: 1,
      }).then((res) => {
        this.taskTypeList = res.data.list;
      })
    },
    // 保存定时任务信息
    saveTaskInfo() {
      saveTaskInfo({
        jobName: this.form.jobName,
        jobCron: this.form.jobCron,
        jobMethod: this.form.jobMethod,
        jobStatus: this.form.jobStatus,
        conTypeId: this.form.conTypeId,
        jopConfig: this.form.jopConfig,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.queryTaskList()
          this.saveDrawer = false;
        }
      })
    },
    // 查询定时任务列表
    queryTaskList() {
      queryTaskList({
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        jobName: this.formInline.jobName,
        jobStatus: this.formInline.jobStatus,
        jobCron: this.formInline.jobCron,
        conTypeId: this.formInline.conTypeId
      }).then((res) => {
        this.taskList = res.data.list;
        this.count = res.data.count;
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.queryTaskList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.queryTaskList()
    },
    // 条件查询
    onSubmit() {
      this.queryTaskList()
    },
    // 跳去任务执行记录页面
    goTaskRecord(index, rows) {
      this.$router.push({
        path: "/taskRecord?conId=" + rows[index].conId
      })
    }
  }
}
</script>

<style scoped>
.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

.drawer-style {
  height: 75%;
  margin-top: 160px;
  margin-right: 400px;
  margin-left: 400px;
  border-radius: 10px;
  text-align: center;
}

.drawer-box {
  width: 100%;
  height: 100%;
  background: #F4F4F4;
}

/deep/ .el-drawer__header {
  background: #F4F4F4;
  font-size: 16px;
  margin-bottom: 0.5px;
  padding: 10px 10px;
}

.addTaskType {
  margin-left: 200px;
  padding-top: 80px;
}

</style>