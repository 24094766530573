<template>
  <el-container>
    <!-- 搜索条件-->
    <div class="grid-content bg-purple">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="标签分类:" style="padding-left: 4px">
          <el-select v-model="formInline.typeId" placeholder="选择标签分类">
            <el-option
                v-for="item in typeList"
                :key="item.labelTypeId"
                :label="item.typeName"
                :value="item.labelTypeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签名字:" style="padding-left: 4px">
          <el-input v-model="formInline.labelName" placeholder="请输入标签的名字"></el-input>
        </el-form-item>
        <el-form-item label="标签描述:" style="padding-left: 4px">
          <el-input v-model="formInline.labelDes" placeholder="请输入标签描述"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100px" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="saveDrawer=true" style="width: 100px">添加标签</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加标签分类的弹窗 -->
    <el-drawer
        title="添加标签信息"
        :visible.sync="saveDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="标签分类:">
              <el-select v-model="form.typeId" placeholder="选择标签分类" style="width: 320px">
                <el-option
                    v-for="item in typeList"
                    :key="item.labelTypeId"
                    :label="item.typeName"
                    :value="item.labelTypeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签名字:">
              <el-input v-model="form.labelName" placeholder="请输入标签的名字"></el-input>
            </el-form-item>
            <el-form-item label="标签描述:">
              <el-input type="textarea" v-model="form.labelDes" placeholder="请输入标签描述"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="saveLabelInfo" style="width: 300px">提交保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 修改标签分类的弹窗 -->
    <el-drawer
        title="修改标签信息"
        :visible.sync="updateDrawer"
        size="100%"
        class="drawer-style">
      <div class="drawer-box">
        <el-container class="addTaskType">
          <el-form ref="form" :model="updateForm" label-width="80px">
            <el-form-item label="标签分类:">
              <el-select v-model="updateForm.typeId" placeholder="选择标签分类" style="width: 320px">
                <el-option
                    v-for="item in typeList"
                    :key="item.labelTypeId"
                    :label="item.typeName"
                    :value="item.labelTypeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签名字:">
              <el-input v-model="updateForm.labelName" placeholder="请输入标签的名字"></el-input>
            </el-form-item>
            <el-form-item label="标签描述:">
              <el-input type="textarea" v-model="updateForm.labelDes" placeholder="请输入标签描述"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="modifyLabelInfo" style="width: 300px">修改保存</el-button>
            </el-form-item>
          </el-form>
        </el-container>
      </div>
    </el-drawer>
    <!-- 记录展示 -->
    <el-container class="listRecord">
      <el-table class="table" height="730"
                :data="labelList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="labelId"
            label="标签ID"
            width="200">
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="标签分类"
            width="100">
        </el-table-column>
        <el-table-column
            prop="labelName"
            label="标签名字"
            width="150">
        </el-table-column>
        <el-table-column
            prop="labelDes"
            label="标签描述"
            width="750">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="updateLabelInfo(scope.$index, labelList)"
                @click="updateDrawer=true"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
import {queryLabelList, queryLabelTypeList, saveLabelInfo, updateLabelInfo} from "../../../module/reqapi/routepath";

export default {
  name: "labelManager",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      formInline: {
        typeId: '',
        labelName: '',
        labelDes: ''
      },
      form: {
        typeId: '',
        labelName: '',
        labelDes: ''
      },
      updateForm: {
        labelId: '',
        typeId: '',
        labelName: '',
        labelDes: ''
      },
      saveDrawer: false,
      updateDrawer: false,
      typeList: [],
      labelList: []
    }
  },
  created() {
    this.queryLabelTypeList()
    this.queryLabelList()
  },
  methods: {
    // 更新标签的的内容
    updateLabelInfo(index, rows) {
      this.updateForm = rows[index];
    },
    modifyLabelInfo() {
      updateLabelInfo({
        labelId: this.updateForm.labelId,
        typeId: this.updateForm.typeId,
        labelName: this.updateForm.labelName,
        labelDes: this.updateForm.labelDes
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.queryLabelList();
          this.updateDrawer = false;
        }
      })
    },
    // 获取标签分类信息列表
    queryLabelTypeList() {
      queryLabelTypeList({
        pageNo: 1,
        pageSize: 1000,
      }).then((res) => {
        this.typeList = res.data.list;
      })
    },
    // 获取标签的集合列表
    queryLabelList() {
      queryLabelList({
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        typeId: this.formInline.typeId,
        labelName: this.formInline.labelName,
        labelDes: this.formInline.labelDes
      }).then((res) => {
        this.labelList = res.data.list;
        this.count = res.data.count;
      })
    },
    // 保存标签信息
    saveLabelInfo() {
      saveLabelInfo({
        typeId: this.form.typeId,
        labelName: this.form.labelName,
        labelDes: this.form.labelDes,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.queryLabelList()
          this.saveDrawer = false;
        }
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.queryLabelList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.queryLabelList()
    },
    // 多条件查询
    onSubmit() {
      this.queryLabelList()
    }
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}

.drawer-style {
  height: 75%;
  margin-top: 160px;
  margin-right: 400px;
  margin-left: 400px;
  border-radius: 10px;
  text-align: center;
}

.drawer-box {
  width: 100%;
  height: 100%;
  background: #F4F4F4;
}

/deep/ .el-drawer__header {
  background: #F4F4F4;
  font-size: 16px;
  margin-bottom: 0.5px;
  padding: 10px 10px;
}

.addTaskType {
  margin-left: 200px;
  padding-top: 200px;
}
</style>