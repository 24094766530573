<template>
  <el-container>
    <div class="com-register">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="公司名字:" prop="companyName">
          <el-input v-model="ruleForm.companyName"></el-input>
        </el-form-item>
        <el-form-item label="机构编号:" prop="orgNumber">
          <el-input v-model="ruleForm.orgNumber"></el-input>
        </el-form-item>
        <el-form-item label="用户名:" prop="userName">
          <el-input v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="昵称:" prop="nickName">
          <el-input v-model="ruleForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="公司简介:" prop="remark">
          <el-input type="textarea" v-model="ruleForm.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">立即申请</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-container>
</template>

<script>
// 导入需要的方法
import {orgRegister} from "../../module/reqapi/routepath";

export default {
  name: "Register",
  data() {
    return {
      ruleForm: {
        companyName: '',
        orgNumber: '',
        userName: '',
        nickName: '',
        password: '',
        email: '',
        phone: '',
        remark: '',
      },
      rules: {
        companyName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
          {min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur'}
        ],
        orgNumber: [
          {required: true, message: '请输入公司机构编号', trigger: 'blur'},
          {min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur'}
        ],
        userName: [
          {required: true, message: '请输入用户名称', trigger: 'blur'},
          {min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入登录密码', trigger: 'blur'},
          {min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur'}
        ],
      }
    };
  },
  created() {
  },
  methods: {
    // 体验的用户申请账号
    submitForm() {
      orgRegister({
        companyName: this.ruleForm.companyName,
        orgNumber: this.ruleForm.orgNumber,
        userName: this.ruleForm.userName,
        nickName: this.ruleForm.nickName,
        password: this.$getRsaCode(this.ruleForm.password),
        email: this.ruleForm.email,
        phone: this.ruleForm.phone,
        remark: this.ruleForm.remark,
      }).then((req) => {
        if (req.code == 200) {
          this.$message.success("申请成功");
          this.$router.push({
            path: "/backlogin"
          })
        } else {
          this.$message.error("申请失败！联系管理员");
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>
.com-register {
  margin-top: 5.5%;
  margin-left: 33%;
  width: 30%;
}
</style>