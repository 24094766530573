<template>
  <div>
    <div class="background">
      <img src="https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/background-1201008_1920.jpg" width="99%"
           height="98.5%" style="position: center">
    </div>
    <div class="content">
      <div class="login_form">
        <div class="clear-fix">
          <table>
            <tr>
              <td style="padding-top: 6px"><img
                  src="https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/favicon.png" height="23px"
                  width="23px" style="margin-top: 2px"></td>
              <td><span>企业后台管理系统</span></td>
            </tr>
          </table>
        </div>
        <el-form ref="form" :model="ruleForm">
          <el-form-item style="margin-top: 15px">
            <table>
              <tr>
                <td style="padding-top: 10px"><img
                    src="https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/user.png" height="17px"
                    width="17px"></td>
                <td></td>
                <td>
                  <el-input v-model="ruleForm.username" placeholder="请输入账号" style="width: 280px; border: 0px"></el-input>
                </td>
              </tr>
            </table>

          </el-form-item>
          <el-form-item style="margin-top: -30px">
            <table>
              <tr>
                <td style="padding-top: 10px"><img
                    src="https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/password.png" height="17px"
                    width="17px"></td>
                <td></td>
                <td>
                  <el-input v-model="ruleForm.password" placeholder="请输入密码" style="width: 280px"/>
                </td>
              </tr>
            </table>
          </el-form-item>
          <el-form-item style="margin-top: 25px">
            <el-button style="width: 300px; margin-left: 6px;" type="primary" @click="doLogin">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="login_foot">
        <h5 class="h5">智能营销系统技术提供商</h5>
        <h5 class="h6">Copyright 2022 szsinet.com All Rights Reserved. 粤ICP备2021083141号</h5>
        <h5 class="h6" style="margin-top: -1px"> 关于思网｜产品介绍｜客户案例｜新闻资讯｜联系我们 </h5>
      </div>
    </div>
  </div>
</template>

<script>
// 导入需要的方法
import {getLogin} from "../../../module/reqapi/routepath";
import {localSave} from "../../../module/reqapi/token.js";
// 页面的方法
export default {
  name: "Login",
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      loginStatus: false,
      checked: false,
      rules: {
        username: [
          {required: true, message: "请输入您的帐户", trigger: "blur"},
        ],
        password: [
          {required: true, message: "请输入您的密码", trigger: "blur"},
        ],
      },
    }
  },
  created() {
  },
  mounted() {
    this.account(); //获取cookie的方法
  },
  methods: {
    // 获取用户信息
    account() {
      if (document.cookie.length >= 0) {
        this.ruleForm.username = this.getCookie("mobile");
        this.ruleForm.password = this.getCookie("password");
      }
    },
    // 写入Cookie
    setCookie(c_name, c_pwd, date) {
      const currentDate = new Date();
      console.log(c_name, c_pwd);
      currentDate.setTime(currentDate.getTime() + 24 * 60 * 60 * 1000 * date); //保存的天数
      document.cookie =
          "mobile=" + c_name + ";path=/;expires=" + currentDate.toLocaleString();
      document.cookie =
          "password=" + c_pwd + ";path=/;expires=" + currentDate.toLocaleString();
    },
    // 获取Cookie
    getCookie(name) {
      let arr = document.cookie.split(";");
      for (let i = 0; i < arr.length; i++) {
        const arr2 = arr[i].split("=");
        if (arr2[0].trim() == name) {
          return arr2[1];
        }
      }
    },
    // 清除Cookie
    clearCookie() {
      this.setCookie("", "", -1);
    },
    // 用户登陆
    doLogin() {
      getLogin({
        userName: this.ruleForm.username,
        password: this.$getRsaCode(this.ruleForm.password),
      }).then((req) => {
        if (req.code == 200) {
          this.$message.success("登录成功");
          this.loginStatus = true;
          const _this = this;
          if (_this.checked == true) {
            //存入cookie
            _this.setCookie(
                _this.ruleForm.username,
                _this.ruleForm.password,
                7
            ); //保存7天
          } else {
            _this.clearCookie();
          }
          // 保存令牌到缓存里面
          localSave(req.data.accessToken);
          this.$router.push({
            path: "/backIndex"
          })
        }
      })
    }
  }
}
</script>

<style>

.clear-fix {
  margin-left: 18%;
  font-size: 18px;
  font-weight: bold;
}

.background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  opacity: 0.8
}

.content {
  z-index: 1;
  position: absolute;
  width: 360px;
  height: 430Px;
  margin-left: 38%;
  margin-top: 16%;
}

.login_form {
  padding: 25px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1)
}

.login_foot {
  margin-top: 40px;
}

.h5{
  text-align: center;
  color: hsla(0, 0%, 3%, 0.55);
  font-size: 14px;
  letter-spacing: 1.75px;
  font-family: PingFangSC-Semibold,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,PingFangSC-Medium;
}

.h6{
  text-align: center;
  margin-top: -15px;
  font-size: 6px;
  color: hsla(0, 0%, 3%, 0.55);
  font-family: PingFangSC-Semibold,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,PingFangSC-Medium;
}

</style>
