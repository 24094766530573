<template>
  <el-container>
    <div style="height: 660px;">

    </div>
  </el-container>
</template>

<script>
export default {
  name: "companyInfo"
}
</script>

<style scoped>

</style>