<template>
  <el-container>
    <!-- 记录展示 -->
    <el-container class="listRecord">
      <el-table class="table" height="800"
                :data="recordList">
        <el-table-column
            fixed
            width="30">
          <el-radio v-model="radio"></el-radio>
        </el-table-column>
        <el-table-column
            fixed
            label="序号"
            width="60"
            type="index">
        </el-table-column>
        <el-table-column
            prop="jobName"
            label="任务名称"
            width="200">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="200">
        </el-table-column>
        <el-table-column
            prop="conStatus"
            label="执行状态"
            width="160">
        </el-table-column>
        <el-table-column
            prop="time"
            label="执行日期"
            width="600">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="120">
          <template slot-scope="scope">
          </template>
        </el-table-column>
      </el-table>
    </el-container>
    <!-- 分页展示-->
    <el-footer style="text-align: center">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
import {queryRecordList} from "../../../module/reqapi/routepath";

export default {
  name: "taskRecord",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      radio: 1,
      count: 0,
      recordList:[]
    }
  },
  created() {
    this.getConRecordList()
  },
  methods: {
    getConRecordList() {
      queryRecordList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        conId: this.$route.query.conId
      }).then((res) => {
        this.recordList = res.data.list;
        this.count = res.data.count;
      })
    },
    // 修改每页的数据
    handleSizeChange(val) {
      this.pageSize = val
      this.getConRecordList()
    },
    // 修改查询的页面
    handleCurrentChange(val) {
      this.pageNo = val
      this.getConRecordList()
    },
  }
}
</script>

<style scoped>

.el-form {
  padding-left: 15px;
}

.el-table {
  border-radius: 4px;
  background: #fbfcfb;
}

.bg-purple {
  padding-top: 20px;
}

.listRecord {
  border-radius: 4px;
  width: 100%;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

.el-footer {
  border-radius: 4px;
  background: #fbfcfb;
  padding-top: 15px;
  height: 60px;
}
</style>