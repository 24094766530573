<template>
    <div class="com-show-box">
      <div>
        <el-carousel indicator-position="outside" height="550px">
          <el-carousel-item v-for="item in imgList" :key="item" style="width: 100%;">
            <el-image :src=item></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div style="height: 200px">

      </div>
    </div>

</template>

<script>
export default {
  name: "comShow",
  data() {
    return {
      imgList: [
        "https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/3-210611163239.jpeg",
        "https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/3-210611163239.jpeg",
        "https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/globe-2491989.jpg",
        "https://sinet-com-dev-1259424727.cos.ap-guangzhou.myqcloud.com/smartphone-6265047.jpg"
      ]
    }
  },
  created() {
  },
  methods: {}
}
</script>

<style scoped>

</style>